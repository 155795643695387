<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm decorativo para janelas</h2>

            <p>
              Os Insulfilmes decorativos dão privacidade ao ambiente e
              proporcionam a sensação de conforto, segurança em residências e
              comércios, beleza estética, privacidade agregando valor à
              propriedade e inovando os ambientes.
            </p>

            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Benefícios do insulfilm decorativo para janelas</h3>

            <p>
              Os insulfilmes decorativos para janelas tem acabamentos
              translúcidos, com desenhos diversos em branco em suaves traços,
              decorações exclusivas e privacidade para seus ambientes.
            </p>

            <p>
              Para quem deseja uma opção bonita, elegante e com estilo para
              customizar e decorar o vidro de sua casa, o insulfilme decorativo
              de vidro residencial é uma boa escolha, ele foi desenvolvido para
              garantir o conforto da sua casa.
            </p>

            <p>
              Essa série de insulfilmes para janelas de vidros tem acabamentos
              de alta qualidade e pode ser utilizada em diversos tipos de
              ambientes para garantir facilidade de uso e trazer conforto para
              sua casa.
            </p>

            <p>
              Quando você quer trazer mais beleza e estilo para a sua casa, o
              vidro é um elemento importante para garantir o efeito desejado. Ao
              contrário das soluções tradicionais que são caras e complexas, o
              uso de insulfilmes residenciais é fácil de implementar, muito
              econômico e pode fornecer uma variedade de acabamentos, texturas e
              estilos.
            </p>

            
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");

export default {
  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
  },

  data() {
    return {
      index: null,
      photos: [
        //decorativo
        {
          description:
            "Aplicação de película Mini Blind para decorar as divisórias de vidro",
          idLocation: 6,
          src: "/images/fotos/condominio-mini-blind/pelicula-decoracao-mini-blind-3",
          thumb:
            "/images/fotos/condominio-mini-blind/small/pelicula-decoracao-mini-blind-3",
          title: "Película para divisória",
        },
      ],
    };
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm decorativo para janelas");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm decorativo para janela em comércios, escritórios, restaurantes e na arquitetura em geral"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>